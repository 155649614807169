<template>
  <div class="indexupload">
    <!-- <div @click="getUploadInfo">getmessage</div> -->
    <div v-if="showimage">
      <div v-if="showerrimge">
        <img src="../assets/illustrate-mobile-folder@3x.png" class="imge" />
        <span class="openFilebtn">
          <div class="ic-upload-album"></div>
          Upload picture
          <input
            type="file"
            name="file"
            id="file"
            accept="image/*"
            ref="FileUpload"
            @change="uploadMethod"
          />
        </span>
      </div>
      <div v-else-if="successcode">
        <img
          src="../assets/illustrate-mobile-folder-success@3x.png"
          class="errimge"
        />
        <p class="errimgetext">Upload successfully！</p>
      </div>
      <div v-else-if="showtime">
        <img src="../assets/img-search-empty-time@3x.png" class="errimge" />
        <p class="errimgetext">
          5 min connection time out, please exitand try again.
        </p>
      </div>
      <div v-else>
        <img src="../assets/img-search-empty@3x.png" class="errimge" />
        <p class="errimgetext">This QR code is no longer vaild.</p>
      </div>
    </div>
    <div v-else class="divimge">
      <div class="uploadimage">
        <div
          class="ic-web-upload-close"
          @click="deleteimage"
          v-if="showdeleteimage"
        ></div>
        <img :src="imageUrl" class="logoImg" />
      </div>
      <div class="openFilebtn" @click="submitMethod" v-if="disUploading">
        {{ showRetry ? "Upload" : "Retry" }}
      </div>
      <div class="openFilebtn" disabled v-else style="background: #d3f5ec">
        Uploading
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "filePhone",
  props: {
    msg: String,
  },
  data() {
    return {
      imageUrl: "",
      code: "",
      url: "",
      Postfile: {
        file: "",
      },
      showimage: true,
      showerrimge: true,
      Uploading: false,
      successcode: false,
      showdeleteimage: true,
      showtime: false,
      showRetry: true,
      disUploading: true,
      isEngage: false,
      ut: "",
      chars: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "M",
        "N",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  computed: {
    uploadMethod() {
      return this.isEngage ? this.EHandleFileUpload : this.handleFileUpload;
    },
    submitMethod() {
      return this.isEngage ? this.ESubmitUpload : this.submitUpload;
    },
  },
  methods: {
    handleFileUpload() {
      this.getQrCodeStatus();
      this.Postfile.file = document.getElementById("file").files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(document.getElementById("file").files[0]);
      this.showimage = false;
    },
    deleteimage() {
      this.getQrCodeStatus();
      this.showimage = true;
      this.imageUrl = "";
    },
    submitUpload() {
      this.getQrCodeStatus();
      this.Uploading = false;
      this.showdeleteimage = false;
      let formData = new FormData();
      this.disUploading = false;
      formData.append("file", this.Postfile.file);
      axios({
        method: "POST",
        url: `https://resource-servertest.engagenewline.com/api/1/res/qrcode/upload `,
        data: formData,
      }).then(
        (res) => {
          this.disUploading = true;
          this.showdeleteimage = true;
          if (res.data.code == "200") {
            this.showimage = true;
            this.showerrimge = false;
            this.successcode = true;
          } else if (res.data.code == "1006") {
            this.showRetry = false;
            this.showdeleteimage = false;
          } else {
            this.showerrimge = false;
          }
        },
        (res) => {
          this.disUploading = true;
          this.showRetry = false;
          if (res.data && res.data.code == "1006") {
            this.showRetry = false;
            this.showdeleteimage = false;
          } else {
            this.showerrimge = false;
          }
        }
      );
    },

    queryURLParams(URL) {
      window.location.href;
      let url = URL.split("?")[1];

      // const urlSearchParams = new URLSearchParams(url);
      // const params = Object.fromEntries(urlSearchParams.entries());
      return url;
    },
    //
    updateStatus() {
      axios({
        method: "POST",
        url: `https://uploadviaphone.teachinfinity.com/t3/qrcode/updateStatus?code=${this.code}`,
      }).then(() => {});
    },
    getQrCodeStatus() {
      axios({
        method: "get",
        url: `https://uploadviaphone.teachinfinity.com/t3/qrcode/getQrCodeStatus?code=${this.code}`,
      }).then(
        (res) => {
          if (res.data.status == "1004") {
            this.showtime = true;
          } else if (res.data.status != "0") {
            this.showerrimge = false;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    // engage文件上传
    init() {
      this.isEngage = true;
    },
    EHandleFileUpload() {
      this.Postfile.file = document.getElementById("file").files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(document.getElementById("file").files[0]);
      this.showimage = false;
    },
    async ESubmitUpload() {
      try {
        let ut = "";
        const message = await this.getTempToken();
        ut = message.data.token;
        this.Uploading = false;
        this.showdeleteimage = false;
        let formData = new FormData();
        this.disUploading = false;
        formData.append("file", this.Postfile.file);
        let requestUrl = `https://resource-servertest.engagenewline.com/api/1/res/qrcode/upload`;
        await axios({
          method: "post",
          url: requestUrl,
          data: formData,
          headers: {
            ut
          },
        });
        this.disUploading = true;
        this.showdeleteimage = true;
        this.showimage = true;
        this.showerrimge = false;
        this.successcode = true;
      } catch (error) {
        this.disUploading = true;
        this.showRetry = false;
        console.error(error);
      }
    },
    getTempToken() {
      return new Promise((resolve) => {
        let clientId = this.generateMixed(10);
        let requestUrl = `https://teachinfinity.com/api/1/user/terminal/getTempToken`;
        axios({
          method: "get",
          url: requestUrl,
          headers: {
            ut: '',
            platform: 'p'
          },
          params: {
            clintId: clientId,
            userId: clientId,
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    generateMixed(n) {
      let res = "";
      let len = 32;
      for (let i = 0; i < n; i++) {
        const id = Math.ceil(Math.random() * len);
        const tmp = this.chars[id];
        if (tmp) {
          res += tmp;
        }
      }
      return res;
    },
  },
  created() {
    this.url = window.location.href;
    // 统一走engage的文件上传
    this.init();
    this.code = this.queryURLParams(this.url);
    this.getQrCodeStatus();
    this.updateStatus();
  },
};
</script>
<style scoped>
.indexupload {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.imge {
  width: 12rem;
  height: 12rem;
  margin-bottom: 6.5rem;
  margin-top: 10rem;
}
.openFilebtn {
  width: 14rem;
  height: 3rem;
  font-size: 1rem;
  line-height: 3rem;
  position: relative;
  display: inline-block;
  background: #07c08e;
  border-radius: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-decoration: none;
  text-indent: 0;
}
.openFilebtn input {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 3rem;
}
.openFilebtn:hover {
  background: #07c08e;
  border-color: #07c08e;
  color: #fff;
  text-decoration: none;
}
.uploadimage {
  border: 1px solid rgba(66, 66, 66, 0.2);
  width: 90%;
  height: 32rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 0.7rem;
  overflow: hidden;
}
.errimge {
  width: 10rem;
  height: 10rem;
  margin-top: 10rem;
}
.divimge {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.errimgetext {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3d3d3d;
}
.ic-web-upload-close {
  background-image: url(../assets/ic-web-upload-close.svg);
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
}
.ic-upload-album {
  background-image: url(../assets/ic-upload-album.svg);
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
  position: absolute;
  top: 15%;
  left: 6%;
}
.logoImg {
  width: 100%;
  max-height: 100%;
}
</style>
